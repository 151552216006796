import { FORM } from '@/constants/builder';
import { HIDDEN } from '@/constants/value';
import { ENUM } from '@/constants/viewTypes';
import * as comparators from '@/constants/comparators';
import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';
import * as onUpdateEffects from './onUpdateEffects';

const dependentProperties = {
  DESCRIPTION: 'description',
  IS_INFORM: 'isInform',
  MEDIA: 'media',
  CLIENT: 'client',
  PROJECT: 'project',
  BUILDING: 'building',
  UNIT: 'unit',
  ROOM: 'room',
  REQUEST: 'request',
  REQUEST_TYPE: 'requestType',
  REQUEST_CATEGORY: 'requestCategory',
  ROLES_PENDING: 'rolesPending',
  ROLES_IN_PROGRESS: 'rolesInProgress',
  ROLES_COMPLETES: 'rolesCompleted',
};

/** @type {import('./schema').default}  */
export const schema = [
  {
    label: 'label.info',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'label.title',
            prop: 'name',
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.ENUM,
            label: 'label.service-types',
            prop: 'serviceType',
            dictionary: dictionaryTypes.SERVICE_TYPES,
            rules: [rules.REQUIRED],
          },
        ],
      },
      {
        type: schemaItemTypes.STRING,
        prop: dependentProperties.DESCRIPTION,
        label: 'label.description',
        textarea: true,
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.CLIENT,
        label: 'label.client',
        prop: dependentProperties.CLIENT,
        payload: [
          { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
          { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
          { param: 'unitId', from: [dependentProperties.UNIT, 'id'] },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: dependentProperties.PROJECT,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.PROJECT,
            label: 'label.project',
            prop: dependentProperties.PROJECT,
            rules: [rules.REQUIRED],
            payload: [{ param: 'clientId', from: [dependentProperties.CLIENT, 'id'] }],
            urlStart: 'issues',
            onUpdate: [
              {
                type: onUpdateEffects.RESET_OR_SET_IF_ONE,
                to: dependentProperties.BUILDING,
              },
              {
                type: onUpdateEffects.REFILL,
                to: dependentProperties.CLIENT,
              },
              {
                type: onUpdateEffects.RESET,
                to: 'roles',
              },
            ],
          },
          {
            type: schemaItemTypes.BUILDING,
            label: 'label.building',
            prop: 'building',
            rules: [rules.REQUIRED],
            urlStart: 'issues',
            payload: [
              { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
              { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: dependentProperties.PROJECT,
                from: 'project',
              },
              {
                type: onUpdateEffects.RESET_OR_SET_IF_ONE,
                to: dependentProperties.UNIT,
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.UNIT,
            label: 'label.unit',
            prop: 'unit',
            rules: [rules.REQUIRED],
            urlStart: 'issues',
            payload: [
              { param: 'buildingId', from: [dependentProperties.BUILDING, 'id'] },
              { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
              { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: dependentProperties.BUILDING,
                from: 'building',
              },
              {
                type: onUpdateEffects.RESET_OR_SET_IF_ONE,
                to: dependentProperties.ROOM,
              },
            ],
          },
          {
            type: schemaItemTypes.ROOM,
            label: 'label.room',
            prop: 'room',
            access: ['rooms'],
            urlStart: 'issues',
            payload: [
              { param: 'unitId', from: [dependentProperties.UNIT, 'id'] },
              { param: 'buildingId', from: [dependentProperties.BUILDING, 'id'] },
              { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
              { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: dependentProperties.UNIT,
                from: 'unit',
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.BOOLEAN,
        prop: 'isInformClientViaPush',
        label: 'tasks.change_status_checkbox_inform',
      },
    ],
  },
  {
    label: 'task.team',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ALERT,
        content: 'request.alert_role_description',
      },
      {
        type: schemaItemTypes.PROJECT_TEAM_FOR_TASK,
        label: 'label.roles',
        prop: 'roles',
        rules: [rules.REQUIRED],
        payload: [
          {
            param: ['projects'],
            from: [dependentProperties.PROJECT, 'id'],
            serializer: serializers.EXTRACT_PARAMS_FROM_SELECT_OBJECT,
          },
        ],
      },
    ],
  },
  {
    label: 'label.deadline',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.NUMBER,
        label: 'label.deadline_full_label',
        prop: 'deadline',
        defaultValue: null,
        rules: [rules.POSITIVE, rules.INTEGER],
      },
    ],
  },
  {
    label: 'label.payments',
    type: schemaItemTypes.TITLED_SECTION,
    rules: [rules.REQUIRED],
    visibility: [{ builderType: FORM, value: HIDDEN }],
    children: [
      {
        type: schemaItemTypes.ENUM,
        viewType: ENUM.radio,
        dictionary: dictionaryTypes.PAYMENT_TYPES,
        prop: 'isPaymentRequired',
        defaultValue: false,
      },
      {
        type: schemaItemTypes.NUMBER,
        label: 'label.amount',
        prop: 'paymentAmount',
        rules: [rules.REQUIRED],
        appendText: 'invoice.euro',
        displayConditions: {
          variables: [
            {
              from: ['isPaymentRequired'],
              comparableValues: [true],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: 'isPaymentRequired',
        },
      },
      {
        type: schemaItemTypes.CONTRACTOR,
        label: 'label.contractor',
        prop: 'paymentContractor',
        rules: [rules.REQUIRED],
        displayConditions: {
          variables: [
            {
              from: ['isPaymentRequired'],
              comparableValues: [true],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: 'isPaymentRequired',
        },
      },
    ],
  },
  {
    prop: 'media',
    type: schemaItemTypes.FILE_LIST,
    rules: [rules.ALL_LOADED],
    removeLabelSuffix: true,
    editable: true,
    accept: '.jpg, .jpeg, .png, .pdf',
  },
];

export const schemaWithTemplate = [
  {
    label: 'label.request',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.REQUEST_TYPE,
        label: 'label.type',
        prop: dependentProperties.REQUEST_TYPE,
        rules: [rules.REQUIRED],
        onUpdate: [
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: dependentProperties.REQUEST_CATEGORY,
          },
        ],
      },
      {
        type: schemaItemTypes.REQUEST_CATEGORY,
        label: 'label.category',
        prop: dependentProperties.REQUEST_CATEGORY,
        rules: [rules.REQUIRED],
        payload: [
          {
            param: 'categoryType',
            from: [dependentProperties.REQUEST_TYPE, 'id'],
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: dependentProperties.REQUEST_TYPE,
            from: 'categoryType',
          },
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: dependentProperties.REQUEST,
          },
        ],
      },
      {
        type: schemaItemTypes.REQUEST,
        label: 'label.title',
        prop: dependentProperties.REQUEST,
        rules: [rules.REQUIRED],
        payload: [
          {
            param: 'requestType',
            from: [dependentProperties.REQUEST_TYPE, 'id'],
          },
          {
            param: 'category',
            from: [dependentProperties.REQUEST_CATEGORY, 'id'],
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: dependentProperties.REQUEST_CATEGORY,
            from: 'category',
          },
        ],
      },
    ],
  },
  {
    label: 'label.clients_type',
    type: schemaItemTypes.MANY_ENUM,
    dictionary: dictionaryTypes.CLIENT_TYPES,
    prop: 'clientType',
    visibility: [{ builderType: FORM, value: HIDDEN }],
  },
  {
    label: 'label.client',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.CLIENT,
        label: 'label.name',
        prop: dependentProperties.CLIENT,
        payload: [
          // { param: 'clientType', from: ['clientType', 0] },
          { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
          { param: 'unitId', from: [dependentProperties.UNIT, 'id'] },
          { param: 'roomId', from: [dependentProperties.ROOM, 'id'] },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: dependentProperties.PROJECT,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.PROJECT,
            label: 'label.project',
            prop: dependentProperties.PROJECT,
            rules: [rules.REQUIRED],
            payload: [{ param: 'clientId', from: [dependentProperties.CLIENT, 'id'] }],
            onUpdate: [
              {
                type: onUpdateEffects.RESET_OR_SET_IF_ONE,
                to: dependentProperties.BUILDING,
              },
              {
                type: onUpdateEffects.REFILL,
                to: dependentProperties.CLIENT,
              },
            ],
          },
          {
            type: schemaItemTypes.BUILDING,
            label: 'label.building',
            prop: 'building',
            rules: [rules.REQUIRED],
            payload: [
              { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
              { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: dependentProperties.PROJECT,
                from: 'project',
              },
              {
                type: onUpdateEffects.RESET_OR_SET_IF_ONE,
                to: dependentProperties.UNIT,
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.UNIT,
            label: 'label.unit',
            prop: 'unit',
            rules: [rules.REQUIRED],
            payload: [
              { param: 'buildingId', from: [dependentProperties.BUILDING, 'id'] },
              { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
              { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: dependentProperties.BUILDING,
                from: 'building',
              },
              {
                type: onUpdateEffects.RESET_OR_SET_IF_ONE,
                to: dependentProperties.ROOM,
              },
            ],
          },
          {
            type: schemaItemTypes.ROOM,
            label: 'label.room',
            prop: 'room',
            payload: [
              { param: 'unitId', from: [dependentProperties.UNIT, 'id'] },
              { param: 'buildingId', from: [dependentProperties.BUILDING, 'id'] },
              { param: 'projectId', from: [dependentProperties.PROJECT, 'id'] },
              { param: 'clientId', from: [dependentProperties.CLIENT, 'id'] },
            ],
            onUpdate: [
              {
                type: onUpdateEffects.SET,
                to: dependentProperties.UNIT,
                from: 'unit',
              },
            ],
          },
        ],
      },
      {
        type: schemaItemTypes.BOOLEAN,
        prop: 'isInformClientViaPush',
        label: 'tasks.change_status_checkbox_inform',
      },
    ],
  },
];

export { schema as default };
